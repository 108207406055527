import { useEffect, useState } from "react";
import { isEqual } from "lodash";

const usePolling = (interval, pollingFunction, deps = []) => {
  const [depSet, setDepSet] = useState(deps);
  if (!isEqual(depSet, deps)) {
    setDepSet(deps);
  }
  const [subscription, setSubscription] = useState(null);
  useEffect(() => {
    const id = setInterval(pollingFunction, interval);
    setSubscription(id);
    return () => {
      if (subscription) {
        clearInterval(subscription);
      }
    };
    // eslint-disable-next-line
  }, [depSet]);
};

export { usePolling };
