import { Container, Col, Row, Alert } from "reactstrap";
import { useLoggedOutAssertion } from "../Utilities/RedirectAssertions";
import LinkButton from "../Components/LinkButton";
import { useTitle } from "../Utilities/Title";
import { useImpression } from "../Utilities/Analytics";

function Authenticate() {
  useLoggedOutAssertion();
  useTitle("Qpid - Logging in...");
  useImpression("Stytch Login Success Redirection Page");
  return (
    <Container>
      <Row>
        <Col xs="auto" className="mx-auto">
          <Alert>
            <p>Please wait a moment while we finish signing you in.</p>
            <LinkButton to="/" color="success">
              Continue
            </LinkButton>
          </Alert>
        </Col>
      </Row>
    </Container>
  );
}

export default Authenticate;
