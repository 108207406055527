import { useState, useRef } from "react";
import { useAuthenticatedAssertion } from "../Utilities/RedirectAssertions";
import { useAPIClient, useAsync } from "../Utilities/API";
import { Spinner, Container, Col, Row, Card } from "reactstrap";
import { useParams } from "react-router-dom";
import Error404 from "../Components/Error404";
import Stars from "../Components/Stars";
import Icons from "../Components/Icons";
import Debug from "../Components/Debug";
import { useTitle } from "../Utilities/Title";
import { useImpression, useTap } from "../Utilities/Analytics";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

const Product = () => {
  useAuthenticatedAssertion();
  useTitle("Qpid - Product Info");
  useImpression("Product Details Page");
  const didTap = useTap();
  let { product_id } = useParams();
  const api = useAPIClient();
  const buySection = useRef();
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [productInfo, setProductInfo] = useState(null);
  const [loading, setLoading] = useState(null);

  useAsync(async () => {
    if (!!product_id && !productInfo && !loading) {
      setLoading(true);
      let request = await api.product(product_id);
      let details = await request.json();
      setProductInfo(details);
      setLoading(false);
    }
  }, [api, product_id]);

  if (!product_id) {
    return <Error404 />;
  } else if (!productInfo) {
    return (
      <Row>
        <Col xs="auto" className="mx-auto">
          <Spinner color="primary" />
        </Col>
      </Row>
    );
  } else {
    return (
      <>
        <Lightbox
          open={lightboxOpen}
          close={() => setLightboxOpen(false)}
          slides={productInfo.images.map((i) => {
            return { src: i };
          })}
        />
        <Container>
          <Row>
            <Col>
              <Card color="light" className="p-3">
                <Row className="g-4">
                  <Col xs="auto">
                    <Row>
                      <Col className="smaller-text caps-header">
                        Preference Score
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="auto g-2">
                        <Card
                          color="success"
                          className="p-2 ms-2 light-text large-text font-monospace"
                        >
                          {`${productInfo.lede.match_value}`}
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Row>
                      <Col className="smaller-text caps-header">Reasons</Col>
                    </Row>
                    <Row>
                      <Col xs="auto g-2" className="horizontal-scroll-flex">
                        {productInfo.lede.reasons.map((i, n) => (
                          <>
                            <Card key={`re-${n}`} className="mx-1 p-2">
                              <dl className="m-0">
                                <dd className="small-text m-0 p-0">
                                  {i.title}
                                </dd>
                                <dt className="m-0 p-0">{i.details}</dt>
                              </dl>
                            </Card>
                          </>
                        ))}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row xs="12 mt-3 flex-row-reverse">
            <Col xs="12" md="6" className="pt-md-3">
              <h2>{productInfo.name}</h2>
              {productInfo.description && <p>{productInfo.description}</p>}
              <table className="mx-auto product-table table table-hover table-borderless table-striped text-start align-middle">
                <thead className="">
                  <tr className="auto">
                    <th>
                      <Icons.Link /> Retailer
                    </th>
                    <th>
                      <Icons.Dollar /> Price
                    </th>
                    <th>
                      <Icons.EmptyStar /> Rating
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {productInfo.vendors.map((v) => {
                    return (
                      <tr key={v.vendor_identifier}>
                        <td>
                          <b>
                            <a
                              className="flair"
                              href={v.url}
                              onClick={() => didTap("product page vendor link")}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {v.name}
                            </a>
                          </b>
                        </td>
                        <td className="small-text">{v.price}</td>
                        <td>
                          <Stars number={v.rating} />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Col>
            <Col xs="12" md="6">
              <Row>
                {productInfo.images.map((i, n) => (
                  <Col xs="6 g-3" key={`im-${n}`}>
                    <a
                      href={i}
                      className="img-link rounded"
                      target="_blank"
                      onClick={(e) => {
                        e.preventDefault();
                        setLightboxOpen(true);
                      }}
                      rel="noreferrer"
                    >
                      <img
                        className="img-fluid rounded"
                        src={i}
                        alt="product"
                      />
                    </a>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
          <Debug>
            <Row>
              <Col>
                <code ref={buySection}>{JSON.stringify(productInfo)}</code>
              </Col>
            </Row>
          </Debug>
        </Container>
      </>
    );
  }
};

export default Product;
