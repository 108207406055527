import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faXTwitter,
  faLinkedin,
  faGoogle,
  faTiktok,
  faInstagram,
  faFacebook,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import {
  faSpinner,
  faRotateRight,
  faBackward,
  faHouse,
  faCircleNotch,
  faAddressCard,
  faAirFreshener,
  faUser,
  faCartShopping,
  faStar as fullStar,
  faStarHalfStroke as halfStar,
  faLink,
  faThumbsUp,
  faThumbsDown,
  faDollarSign,
  faQuestion,
  faCheck,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";

import {
  faSquareCheck,
  faSquare,
  faCircleXmark,
  faStar as emptyStar,
} from "@fortawesome/free-regular-svg-icons";

const iconMap = {
  Spinner: faSpinner,
  SpinnerCircle: faCircleNotch,
  AddressCard: faAddressCard,
  AirFreshener: faAirFreshener,
  Twitter: faXTwitter,
  LinkedIn: faLinkedin,
  Google: faGoogle,
  TikTok: faTiktok,
  Instagram: faInstagram,
  Facebook: faFacebook,
  YouTube: faYoutube,
  SquareCheck: faSquareCheck,
  Square: faSquare,
  Human: faUser,
  CircleX: faCircleXmark,
  ShoppingCart: faCartShopping,
  FullStar: fullStar,
  EmptyStar: emptyStar,
  HalfStar: halfStar,
  Link: faLink,
  Dollar: faDollarSign,
  QuestionMark: faQuestion,
  ThumbUp: faThumbsUp,
  ThumbDown: faThumbsDown,
  Check: faCheck,
  XMark: faXmark,
  Back: faBackward,
  Home: faHouse,
  Refresh: faRotateRight,
};

const icons = Object.entries(iconMap).reduce(function (acc, pair) {
  const [key, value] = pair;
  acc[key] = (props) => (
    <FontAwesomeIcon
      icon={value}
      // style={{ color: props.color ?? "#000000" }}
      {...props}
    />
  );
  return acc;
}, {});

export default icons;
