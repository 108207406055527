import { MenuBody } from "../Components/Menu";
import { useTitle } from "../Utilities/Title";
import { useImpression } from "../Utilities/Analytics";

const AccountMenu = () => {
  useTitle("Qpid - Account actions");
  useImpression("AccountMenu");
  return <MenuBody />;
};
export default AccountMenu;
