import { useEffect } from "react";
import { useStytchUser, useStytchSession } from "@stytch/react";
import { useNavigate } from "react-router-dom";

function useAuthenticatedAssertion(path = "/signup") {
  const { user } = useStytchUser();
  const { session } = useStytchSession();
  const navigate = useNavigate();
  useEffect(() => {
    if (!session || !user) {
      navigate(path);
    }
  }, [session, user, path, navigate]);
}

function useLoggedOutAssertion(path = "/") {
  const navigate = useNavigate();
  const { user } = useStytchUser();
  const { session } = useStytchSession();
  useEffect(() => {
    if (session || user) {
      navigate(path);
    }
  }, [session, user, navigate, path]);
}

function useBasicInfoCollectionRedirect(path = "/require-account") {
  const { user } = useStytchUser();
  const navigate = useNavigate();
  useEffect(() => {
    if (!!user && (!user.name.first_name || !user.name.first_name)) {
      navigate(path);
    }
  }, [user, navigate, path]);
}

function useIsAuthenticated() {
  const { user } = useStytchUser();
  const { session } = useStytchSession();
  return !!session && !!user;
}

export {
  useAuthenticatedAssertion,
  useBasicInfoCollectionRedirect,
  useLoggedOutAssertion,
  useIsAuthenticated,
};
