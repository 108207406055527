import { useCallback } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, CardBody, Container } from "reactstrap";
import { useTap } from "../Utilities/Analytics";

const MenuBody = ({ sideEffect }) => {
  return (
    <Container>
      <Row className="text-start">
        <Col xs="auto gy-1">
          <span className="caps-header smaller-text">Account Settings</span>
          <Container>
            <Row>
              <Col xs="auto gy-2">
                <Link to="/account" onClick={sideEffect} className="flair">
                  My Account
                </Link>
              </Col>
            </Row>
            <Row>
              <Col xs="auto gy-2">
                <Link to="/logout" onClick={sideEffect} className="flair">
                  Log Out
                </Link>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
      <Row className="text-start">
        <Col xs="auto gy-4">
          <span className="caps-header smaller-text">Coming soon</span>
          <Container>
            <Row>
              <Col xs="auto gy-2">
                <span className="disabled-link">Consumer Electronics</span>
              </Col>
            </Row>
            <Row>
              <Col xs="auto gy-2">
                <span className="disabled-link">Home Furnishings</span>
              </Col>
            </Row>
            <Row>
              <Col xs="auto gy-2">
                <span className="disabled-link">Toys and Hobby</span>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

const Menu = ({ clickSideEffect = null }) => {
  const didTap = useTap();
  const sideEffect = useCallback(() => {
    didTap("menu item");
    if (!!clickSideEffect) {
      clickSideEffect();
    }
  }, [clickSideEffect, didTap]);

  return (
    <Container>
      <Row>
        <Col xs="auto px-5 my-2" onClick={sideEffect} onMouseLeave={sideEffect}>
          <Card className="mb-5 shadow" onClick={(e) => e.stopPropagation()}>
            <CardBody>
              <MenuBody sideEffect={sideEffect} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export { Menu, MenuBody };
