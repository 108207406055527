import { useMemo } from "react";
import { useStytch } from "@stytch/react";

function useSessionToken() {
  const stytchClient = useStytch();

  const [sessionTokens] = useMemo(() => {
    return [stytchClient.session.getTokens()];
  }, [stytchClient.session]);

  return sessionTokens?.session_jwt;
}

export { useSessionToken };
