import { Container, Col, Row } from "reactstrap";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ErrorBoundary from "./Pages/ErrorBoundary";
import TypeFormDisplay, {
  FEEDBACK_TYPE,
  NEW_REQUEST_TYPE,
  REDO_REQUEST_TYPE,
} from "./Pages/TypeFormDisplay";
import { useTokenAuth } from "./Utilities/TokenAuth";
import { useAuthMaintenance } from "./Utilities/AuthMaintenance";
import MyRequests from "./Pages/MyRequests";
import Authenticate from "./Pages/Authenticate";
import Error404 from "./Components/Error404";
import Product from "./Pages/Product";
import AccountMenu from "./Pages/AccountMenu";
import Recommendations from "./Pages/Recommendations";
import Membership from "./Pages/Membership";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Login from "./Pages/Login";
import Signup from "./Pages/Signup";
import Logout from "./Pages/Logout";
import UserInfo from "./Pages/UserInfo";

function App() {
  useTokenAuth();
  useAuthMaintenance();

  return (
    <BrowserRouter>
      <Container>
        <Row>
          <Col xs="12" md="10" xl="8" className="mx-auto">
            <Header />
            <ErrorBoundary>
              <Routes>
                {/* Auth independent*/}
                <Route index element={<MyRequests />} />
                <Route path="authenticate" element={<Authenticate />} />

                {/* Page requires logged-out state, or redirect to login.*/}
                <Route path="login" element={<Login />} />
                <Route path="signup" element={<Signup />} />

                {/* Pages require Authenticated state, or redirect.*/}
                <Route path="my-requests" element={<MyRequests />} />
                <Route path="logout" element={<Logout />} />
                <Route
                  path="require-account"
                  element={
                    <UserInfo continueToPath="/">
                      <>
                        <h2>Welcome!</h2>
                        <p>What should we call you?</p>
                      </>
                    </UserInfo>
                  }
                />
                <Route path="membership" element={<Membership />} />
                <Route path="account-menu" element={<AccountMenu />} />
                <Route
                  path="account"
                  element={
                    <UserInfo>
                      <h2>My Account</h2>
                      <br />
                    </UserInfo>
                  }
                />
                <Route
                  path="redo-request/:request_category_slug/:typeform_form_id"
                  element={<TypeFormDisplay type={REDO_REQUEST_TYPE} />}
                />
                <Route
                  path="new-request/:request_category_slug/:typeform_form_id"
                  element={<TypeFormDisplay type={NEW_REQUEST_TYPE} />}
                />
                <Route
                  path="feedback/:request_category_slug/:typeform_form_id"
                  element={<TypeFormDisplay type={FEEDBACK_TYPE} />}
                />
                <Route path="product/:product_id" element={<Product />} />
                <Route
                  path="recommendation/:request_category_slug/:typeform_form_id"
                  element={<Recommendations />}
                />

                {/* Auth independent*/}
                <Route path="404" element={<Error404 homeLink="/" />} />
                <Route path="*" element={<Error404 homeLink="/" />} />
              </Routes>
            </ErrorBoundary>
            <Footer />
          </Col>
        </Row>
      </Container>
    </BrowserRouter>
  );
}

export default App;
