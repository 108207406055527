import { useEffect, useState } from "react";
import * as amplitude from "@amplitude/analytics-browser";

function useImpression(name) {
  useEffect(() => {
    if (!!name) {
      amplitude.track(`impression: ${name}`);
    }
  }, [name]);
}

function useTap() {
  const [lastTap, setLastTap] = useState(null);
  useEffect(() => {
    if (!!lastTap) {
      amplitude.track(`tap: ${lastTap}`);
    }
  }, [lastTap]);
  return setLastTap;
}

function useAnalyicsUserID() {
  const [id, setID] = useState(null);
  useEffect(() => {
    amplitude.setUserId(id);
  }, [id]);
  return setID;
}

export { useImpression, useAnalyicsUserID, useTap };
