import Constants from "../Constants";
import { useSessionToken } from "./SessionAccess";
import { useEffect, useMemo, useState } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { isEqual } from "lodash";

class APIUsageError extends Error {
  toString() {
    return `APIUsageError: ${this.message}${
      !!this.cause ? ` [${this.cause}]` : ``
    }`;
  }
}
class NetworkError extends Error {
  toString() {
    return `NetworkError: ${this.message}${
      !!this.cause ? ` [${this.cause}]` : ``
    }`;
  }
}

const isDev = () => {
  return Constants.APP_DOMAIN.includes("localhost:");
};

const useAsync = (asyncAct, deps = []) => {
  const { showBoundary } = useErrorBoundary();
  useEffect(
    function () {
      const func = async () => {
        try {
          // eslint-disable-next-line
          await asyncAct();
        } catch (error) {
          showBoundary(error);
        }
      };
      func();
    },
    // eslint-disable-next-line
    [...deps]
  );
};

async function api(endpoint, token, method = "GET", body = null, headers = {}) {
  if (!token) {
    throw new APIUsageError(`Unauthenticated ${method} to ${endpoint}`);
  }
  let requestHeaders = new Headers({
    "Content-Type": "application/json; charset=utf-8",
    mode: "cors",
    Origin: Constants.APP_DOMAIN,
    "X-Qpid-Token": token,
    "Access-Control-Request-Method": method,
    "Access-Control-Request-Headers": "Content-Type, X-Qpid-Token",
  });
  for (const i in headers) {
    requestHeaders.append(i, headers[i]);
  }
  let requestOptions = {
    method: method,
    credentials: "include",
    headers: requestHeaders,
  };
  let path = `${Constants.API_DOMAIN}/${endpoint}`;
  if (!!body) {
    if (method === "GET" || method === "HEAD") {
      let params = new URLSearchParams(body);
      path = `${path}?${params.toString()}`;
    } else {
      let formData = new FormData();
      for (const v in body) {
        formData.set(v, body[v]);
      }
      requestOptions["body"] = formData;
    }
  }
  const request = new Request(path, requestOptions);
  return new Promise((resolve, reject) => {
    fetch(request)
      .then((response) => {
        if (!response.ok) {
          reject(
            new NetworkError(
              `Error response received: ${response.status} ${response.statusText}`,
              { cause: Error(response.cause) }
            )
          );
        } else {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(new NetworkError(`Could not reach server.`, { cause: error }));
      });
  });
}

function requestClient(token) {
  async function apiQuery(endpoint, method = "GET", data = null, headers = {}) {
    return api(endpoint, token, method, data, headers);
  }
  async function useGET(endpoint, params = {}, headers = {}) {
    return apiQuery(
      endpoint,
      "GET",
      isEqual(params, {}) ? null : params,
      headers
    );
  }
  async function usePOST(endpoint, data = {}, headers = {}) {
    return apiQuery(endpoint, "POST", isEqual(data, {}) ? null : data, headers);
  }
  return { get: useGET, post: usePOST };
}

const cacheApplies = (token) => globalThis["qpid_last_token"] === token;
const resetCacheIfNeeded = (token) => {
  if (cacheApplies(token)) {
    return true;
  } else {
    globalThis["qpid_request_cache"] = {};
    globalThis["qpid_last_token"] = token;
    return false;
  }
};
const getCacheValue = (token, key) =>
  (!!token &&
    !!key &&
    resetCacheIfNeeded(token) &&
    !!globalThis["qpid_request_cache"] &&
    globalThis["qpid_request_cache"][key]) ||
  null;
const setCacheValue = (token, key, value) => {
  if (!!token && !!key && cacheApplies(token)) {
    globalThis["qpid_request_cache"][key] = value;
  }
};

const useCachedGET = (endpoint, canReload) => {
  let token = useSessionToken();
  let [data, setData] = useState(getCacheValue(token, endpoint));
  let [isLoading, setIsLoading] = useState(true);

  useAsync(async () => {
    try {
      const result = await api(endpoint, token);
      const data = await result.json();
      setData(data);
      setIsLoading(false);
      setCacheValue(token, endpoint, data);
    } catch (error) {
      setData(null);
      setIsLoading(false);
      setCacheValue(token, endpoint, null);
      error.isTemporary = !!canReload;
      throw error;
    }
  }, []);
  return { data, isLoading };
};

const useAPIClient = () => {
  let token = useSessionToken();
  return useMemo(() => {
    const client = requestClient(token);
    return {
      token: async () => {
        return client.get("token");
      },
      like: async (product_id) => {
        if (!product_id) {
          throw new APIUsageError("no product id");
        }
        return client.post("like", { product_id: product_id });
      },
      dislike: async (product_id) => {
        if (!product_id) {
          throw new APIUsageError("no product id");
        }
        return client.post("dislike", { product_id: product_id });
      },
      useCachedUserRequests: () => useCachedGET("user_requests", true),
      useCachedRecommendations: (recommendation_id) =>
        useCachedGET(`get_recommendations/${recommendation_id}`, false),
      recommendations: async (recommendation_id) => {
        if (!recommendation_id) {
          throw new APIUsageError("no recommendation id");
        }
        return client.get(`get_recommendations/${recommendation_id}`);
      },
      subscription_status: async () => {
        return client.get("subscription_status");
      },
      product: async (product_id) => {
        if (!product_id) {
          throw new APIUsageError("no product id");
        }
        return client.get(`product/${product_id}`);
      },
      user_requests: async () => {
        return client.get("user_requests");
      },
      resolve_recommendation: async (
        typeform_form_id,
        request_category_slug,
        typeform_response_id
      ) => {
        if (!typeform_form_id) {
          throw new APIUsageError("no typeform_form_id");
        }
        if (!request_category_slug) {
          throw new APIUsageError("no request_category_slug");
        }
        if (!typeform_response_id) {
          throw new APIUsageError("no typeform_response_id");
        }
        return client.get(
          `resolve_recommendation/${typeform_form_id}/${request_category_slug}/${typeform_response_id}`
        );
      },
    };
  }, [token]);
};

export { useAPIClient, useAsync, isDev };
