import React from "react";
import { Container, Col, Row } from "reactstrap";
import Divider from "./Divider";
import Icons from "./Icons";
import { useTap } from "../Utilities/Analytics";

function Footer(props = { dark: false }) {
  const didTap = useTap();
  return (
    <Container>
      <Row className="my-5">
        <Col>
          <Row className="align-items-center justify-content-center">
            <Col xs="auto">
              <a
                href="https://www.qpid.shop/about"
                onClick={() => didTap("footer about")}
                className="footer-nav-link"
              >
                About
              </a>
            </Col>
            <Col xs="auto">
              <a
                href="https://www.qpid.shop/privacy-policy"
                className="footer-nav-link"
                onClick={() => didTap("footer privacy")}
              >
                Privacy Policy
              </a>
            </Col>
            <Col xs="auto">
              <a
                href="https://www.qpid.shop/terms-of-service"
                className="footer-nav-link"
                onClick={() => didTap("footer tos")}
              >
                Terms of Service
              </a>
            </Col>
          </Row>
          <Row className="my-4">
            <Divider />
          </Row>
          <Row className="align-items-center justify-content-center my-4">
            <Col xs="auto mx-3">Copyright © 2023 ShopQpid, Inc</Col>
            <Col xs="auto mx-3">
              <Row>
                <Col>
                  <a
                    href="https://www.instagram.com/shop.qpid/"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => didTap("footer instagram")}
                  >
                    <Icons.Instagram color="black" />
                  </a>
                </Col>
                <Col>
                  <a
                    href="https://www.tiktok.com/@shopqpid"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => didTap("footer tiktok")}
                  >
                    <Icons.TikTok color="black" />
                  </a>
                </Col>
                <Col>
                  <a
                    href="http://youtube.com/@shopqpid"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => didTap("footer youtube")}
                  >
                    <Icons.YouTube color="black" />
                  </a>
                </Col>
                <Col>
                  <a
                    href="https://x.com/ShopQpid"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => didTap("footer xtwitter")}
                  >
                    <Icons.Twitter color="black" />
                  </a>
                </Col>
                <Col>
                  <a
                    href="https://www.facebook.com/shopqpid"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => didTap("footer facebook")}
                  >
                    <Icons.Facebook color="black" />
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
export default Footer;
