import { useAuthenticatedAssertion } from "../Utilities/RedirectAssertions";
import { useAPIClient } from "../Utilities/API";
import LinkButton from "../Components/LinkButton";
import { Spinner, Container, Col, Row, Card } from "reactstrap";
import { useParams, Link, useNavigate } from "react-router-dom";
import Icons from "../Components/Icons";
import Error404 from "../Components/Error404";
import { useTitle } from "../Utilities/Title";
import { useImpression } from "../Utilities/Analytics";
import { useTap } from "../Utilities/Analytics";
import { useErrorBoundary } from "react-error-boundary";

const Recommendations = () => {
  useAuthenticatedAssertion();
  let { request_category_slug, typeform_form_id } = useParams();
  const api = useAPIClient();
  useTitle("Qpid - Recommendations");
  useImpression("Recommendations Page");
  const { showBoundary } = useErrorBoundary();
  const showError = showBoundary;
  const didTap = useTap();
  const navigate = useNavigate();
  const { data, isLoading, error } = api.useCachedRecommendations(
    request_category_slug
  );
  const recommendations = data?.recommendations;
  const title = data?.title ?? "Loading";

  if (!request_category_slug || !typeform_form_id || error) {
    return <Error404 />;
  } else if (recommendations) {
    return (
      <Container>
        <Row>
          <Col>
            <Row>
              <Col xs="auto">
                <h2>{`Your ${title} Recommendations`}</h2>
              </Col>
            </Row>
            <Row>
              <Col>
                <table className="product-table table table-hover text-center align-middle">
                  <thead>
                    <tr>
                      <th>Image</th>
                      <th>Name</th>
                      <th>Price</th>
                      <th>Preference Score</th>
                      <th>Rate Product</th>
                    </tr>
                  </thead>
                  <tbody>
                    {recommendations.map((row) => {
                      return (
                        <tr key={row.product_id}>
                          <td>
                            <Link
                              to={`/product/${row.product_id}`}
                              onClick={() =>
                                didTap("recommendation page product image link")
                              }
                            >
                              <img
                                src={row.image}
                                alt={row.product_name}
                                className="img-fluid img-thumbnail rec-thumbnail"
                              />
                            </Link>
                          </td>
                          <td className="text-start">
                            <Link
                              className="flair"
                              to={`/product/${row.product_id}`}
                              onClick={() =>
                                didTap("recommendation page product link")
                              }
                            >
                              {row.product_name}
                            </Link>
                          </td>
                          <td className="font-monospace">{`$${row.details["Min Price"]}`}</td>
                          <td className="align-end">
                            <Card
                              color="success"
                              className="light-text p-1 m-0 small-text font-monospace inline-block"
                            >{`${row.score}`}</Card>
                          </td>
                          <td>
                            <Container>
                              <Row className="auto p-0 justify-content-center">
                                <Col xs="6">
                                  <Icons.ThumbUp
                                    className="activatable-50 activatable-success"
                                    onClick={() => {
                                      didTap("like");
                                      api
                                        .like(row.product_id)
                                        .then(() =>
                                          navigate(
                                            `/feedback/${request_category_slug}/pTHZOLwQ`
                                          )
                                        )
                                        .catch((error) => {
                                          error.isTemporary = true;
                                          showError(error);
                                        });
                                    }}
                                  />
                                </Col>
                                <Col xs="6">
                                  <Icons.ThumbDown
                                    className="activatable-50 activatable-danger"
                                    onClick={() => {
                                      didTap("dislike");
                                      api
                                        .dislike(row.product_id)
                                        .then(() =>
                                          navigate(
                                            `/feedback/${request_category_slug}/pTHZOLwQ`
                                          )
                                        )
                                        .catch((error) => {
                                          error.isTemporary = true;
                                          showError(error);
                                        });
                                    }}
                                  />
                                </Col>
                              </Row>
                            </Container>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Col>
            </Row>
            <Row className="justify-content-end align-items-center row-gap-2">
              <Col xs="auto" className="small-text">
                Want to change your preferences?
              </Col>
              <Col xs="auto">
                <LinkButton
                  to={`/redo-request/${request_category_slug}/${typeform_form_id}`}
                  color="primary"
                  className="small-text"
                  onClick={() => didTap("redo request")}
                >
                  Restart Request
                </LinkButton>
              </Col>
              <Col xs="auto">
                <LinkButton
                  to={`/feedback/${request_category_slug}/uFc3nL4y`}
                  color="secondary"
                  className="small-text"
                  onClick={() => didTap("redo request")}
                >
                  Provide Feedback
                </LinkButton>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs="auto" className="mx-auto">
            <Spinner
              color="primary"
              size="sm"
              type="grow"
              className={isLoading ? "opacity-100" : "opacity-0"}
            />
          </Col>
        </Row>
      </Container>
    );
  } else {
    return (
      <>
        <Row>
          <Col>
            <h2>Your Recommendations</h2>
            <p>Loading...</p>
          </Col>
        </Row>
        <Row>
          <Col xs="auto" className="mx-auto">
            <Spinner color="primary" />
          </Col>
        </Row>
      </>
    );
  }
};

export default Recommendations;
