import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  UncontrolledCollapse,
  NavbarBrand,
  Container,
  Card,
} from "reactstrap";
import { Popover, PopoverTrigger, PopoverContent } from "./Popover";
import { Menu, MenuBody } from "./Menu";
import {
  IfAuthenticated,
  IfNotAuthenticated,
} from "../Stytch/AuthenticationComponents";
import { useTap } from "../Utilities/Analytics";
import Constants from "../Constants";
import Icons from "./Icons";
import { BT, Show } from "../Utilities/Breakpoint";

function Header(args) {
  const [open, setOpen] = useState(false);
  const didTap = useTap();
  return (
    <Container>
      <Row className="justify-content-between align-items-center my-5">
        <Col xs="12" sm="3" className="g-2 text-sm-start text-center">
          <Link to="/" onClick={() => didTap("header logo")}>
            <NavbarBrand
              tag="img"
              href={`${Constants.APP_DOMAIN}`}
              src="/qpid-logo.png"
              alt="Qpid Logo"
              style={{ width: "100px", objectFit: "contain" }}
            />
          </Link>
        </Col>
        <IfAuthenticated>
          <Col xs="12" sm="6" className="text-center g-2">
            <Row className="justify-content-around">
              <Col xs="12" sm="6">
                <b>
                  <Link
                    onClick={() => didTap("header my requests")}
                    to="/my-requests"
                    className="flair"
                  >
                    My&nbsp;Requests
                  </Link>
                </b>
              </Col>
              <Col xs="12" sm="6">
                <b>
                  <Link
                    onClick={() => didTap("header membership")}
                    to="/membership"
                    className="flair"
                  >
                    Membership
                  </Link>
                </b>
              </Col>
            </Row>
          </Col>
          <Show upto={BT.sm}>
            <Col xs="auto" className="mx-auto d-flow text-center">
              <a
                href="/account-menu"
                id="toggler"
                className="link-button inline-block"
                onClick={(e) => {
                  didTap("header account icon");
                  e.preventDefault();
                  setOpen((v) => !v);
                }}
              >
                <Icons.Human size="xl" className="activatable-50 mt-2" />
              </a>
              <UncontrolledCollapse toggler="#toggler">
                <Card className="mt-2 p-2">
                  <MenuBody />
                </Card>
              </UncontrolledCollapse>
            </Col>
          </Show>
          <Show from={BT.sm}>
            <Col xs="12" sm="3" className="g-2 text-sm-end text-center">
              <Popover open={open} onOpenChange={setOpen}>
                <PopoverTrigger>
                  <a
                    href="/account-menu"
                    className="link-button inline-block"
                    onClick={(e) => {
                      didTap("header account icon");
                      e.preventDefault();
                      setOpen((v) => !v);
                    }}
                  >
                    <Icons.Human size="xl" className="activatable-50" />
                  </a>
                </PopoverTrigger>
                <PopoverContent>
                  <Menu clickSideEffect={() => setOpen(false)} />
                </PopoverContent>
              </Popover>
            </Col>
          </Show>
        </IfAuthenticated>
        <IfNotAuthenticated>
          <Col xs="6 text-center">
            <b>
              <Link
                onClick={() => didTap("header membership")}
                to="/membership"
                className="flair"
              >
                Membership
              </Link>
            </b>
          </Col>
          <Col xs="3">&nbsp;</Col>
        </IfNotAuthenticated>
      </Row>
    </Container>
  );
}

export default Header;
