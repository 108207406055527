import React, { useState } from "react";
import { Widget } from "@typeform/embed-react";
import { useAuthenticatedAssertion } from "../Utilities/RedirectAssertions";
import { useParams, Navigate } from "react-router-dom";
import { useAPIClient } from "../Utilities/API";
import { Spinner, Row, Col, Container } from "reactstrap";
import Error404 from "../Components/Error404";
import { useStytchSession } from "@stytch/react";
import { useTitle } from "../Utilities/Title";
import { useImpression } from "../Utilities/Analytics";

const NEW_REQUEST_TYPE = "new-request";
const REDO_REQUEST_TYPE = "redo-request";
const FEEDBACK_TYPE = "feedback";

const TypeFormDisplay = ({ type }) => {
  useAuthenticatedAssertion();
  let { request_category_slug, typeform_form_id } = useParams();
  const [typeFormResponseID, setTypeFormResponseID] = useState(null);
  const [continuationPath, setContinuationPath] = useState(null);
  const { session } = useStytchSession();
  const api = useAPIClient();
  let waitingInfo = {};
  let title = "";
  switch (type) {
    case NEW_REQUEST_TYPE:
      title = `Qpid - New Request ${request_category_slug ?? ""}`;
      waitingInfo = {
        header: "Just a second!",
        body: "Qpid is generating your preference profile...",
        showSpinner: true,
      };
      break;
    case REDO_REQUEST_TYPE:
      title = `Qpid - Re-request ${request_category_slug ?? ""}`;
      waitingInfo = {
        header: "Just a second!",
        body: "Qpid is generating your preference profile...",
        showSpinner: true,
      };
      break;
    case FEEDBACK_TYPE:
      title = `Qpid - Feedback`;
      waitingInfo = {
        header: "Feedback Submitted",
        body: "We're always looking to improve Qpid. Thanks for taking the time to give us your thoughts!",
        showSpinner: false,
      };
      break;
    default:
      throw new Error("invalid or missing request type");
  }
  useTitle(title);
  useImpression(`Make Request Page (${type})`);

  const handleSubmit = async ({ formId, responseId }) => {
    setTypeFormResponseID(responseId);
    if (type === FEEDBACK_TYPE) {
      // Show waiting text with thank you.
      return;
    }
    const data = await api.resolve_recommendation(
      formId,
      request_category_slug,
      responseId
    );
    const value = await data.json();
    setContinuationPath(
      `/recommendation/${value.request_category_slug}/${value.typeform_form_id}`
    );
  };

  const widgetContainerStyle = {
    width: "80%",
    height: "800px",
    margin: "0 auto",
  };

  if (!request_category_slug || !typeform_form_id) {
    return <Error404 />;
  } else if (!typeFormResponseID) {
    return (
      <Container>
        <Row>
          <Col>
            <Widget
              id={typeform_form_id}
              style={widgetContainerStyle}
              medium="demo-test"
              hidden={{
                session_id: session.session_id,
                request_category_slug: request_category_slug,
              }}
              transitiveSearchParams={[]}
              iframeProps={{ title: "Qpid preferences" }}
              onSubmit={handleSubmit}
            />
          </Col>
        </Row>
      </Container>
    );
  } else if (!continuationPath) {
    return (
      <Container>
        <Row>
          <Col xs="auto" className="mx-auto">
            <h3>{waitingInfo["header"] ?? "Just a second!"}</h3>
            <p>
              {waitingInfo["body"] ??
                "Qpid is generating your preference profile..."}
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs="auto" className="mx-auto">
            {waitingInfo["showSpinner"] && <Spinner color="primary" />}
          </Col>
        </Row>
      </Container>
    );
  } else {
    return <Navigate to={`${continuationPath}`} />;
  }
};
export {
  TypeFormDisplay as default,
  NEW_REQUEST_TYPE,
  REDO_REQUEST_TYPE,
  FEEDBACK_TYPE,
};
