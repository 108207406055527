import { useImpression, useTap } from "../Utilities/Analytics";
import ErrorUI from "./ErrorUI";

const Error404 = (props) => {
  useImpression("404 Component");
  const didTap = useTap();
  return (
    <ErrorUI
      message="This page couldn't be found."
      didTapHome={() => {
        didTap("404 Error home button");
      }}
    />
  );
};

export default Error404;
