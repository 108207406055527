import Icons from "../Components/Icons";

function Stars(props) {
  if (!props.number) {
    return (
      <>
        <div
          className="rounded inline-block mx-auto py-1 px-2 small-text"
          style={{ backgroundColor: "lightgray" }}
          title="No rating information is available from this retailer."
        >
          No Rating
        </div>
      </>
    );
  }
  let ratingResult = [];
  let ratingCount = Math.round(props.number * 2.0);
  while (ratingResult.length < 5) {
    if (ratingCount >= 2.0) {
      ratingResult.push(
        <Icons.FullStar
          color="var(--star-fg-orange)"
          key={ratingResult.length}
        />
      );
    } else if (ratingCount >= 1.0) {
      ratingResult.push(
        <Icons.HalfStar
          color="var(--star-fg-orange)"
          key={ratingResult.length}
        />
      );
    } else {
      ratingResult.push(
        <Icons.EmptyStar
          color="var(--star-fg-orange)"
          key={ratingResult.length}
        />
      );
    }
    ratingCount -= 2;
  }
  return (
    <div
      className="rounded inline-block mx-auto p-1"
      style={{ backgroundColor: "var(--star-bg-yellow)" }}
      title={`${props.number ?? "unknown"} stars`}
    >
      {ratingResult}
    </div>
  );
}

export default Stars;
