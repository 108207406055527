import Login from "../Components/Login";
import { useLoggedOutAssertion } from "../Utilities/RedirectAssertions";
import { useTitle } from "../Utilities/Title";
import { useImpression } from "../Utilities/Analytics";

function LoginPage() {
  useLoggedOutAssertion();
  useTitle("Qpid - Log in");
  useImpression("Login Page");
  return <Login />;
}

export default LoginPage;
