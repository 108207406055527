import React from "react";
import { useStytchUser, useStytchSession } from "@stytch/react";

function IfAuthenticated({ children }) {
  const { user } = useStytchUser();
  const { session } = useStytchSession();
  if (session && user) {
    return <>{children}</>;
  } else {
    return <></>;
  }
}

function IfNotAuthenticated({ children }) {
  const { user } = useStytchUser();
  const { session } = useStytchSession();
  if (!session || !user) {
    return <>{children}</>;
  } else {
    return <></>;
  }
}

export { IfAuthenticated, IfNotAuthenticated };
