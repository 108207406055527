import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Container, Alert, Spinner } from "reactstrap";
import { useAPIClient } from "../Utilities/API";
import { useStytchUser } from "@stytch/react";
import { useAuthenticatedAssertion } from "../Utilities/RedirectAssertions";
import { useBasicInfoCollectionRedirect } from "../Utilities/RedirectAssertions";
import { useTitle } from "../Utilities/Title";
import { useImpression } from "../Utilities/Analytics";

const itemToDataDisplayItem = (item) => {
  return {
    name: item.name,
    description: item.description,
    display_sort_order: item.display_sort_order,
    key: item.request_category_slug,
    link_path: !!item.is_completed
      ? `/recommendation/${item.request_category_slug}/${item.typeform_form_id}`
      : `/new-request/${item.request_category_slug}/${item.typeform_form_id}`,
    style: !!item.is_completed ? "primary" : "light",
    thumbnail_url: item.thumbnail_url,
    complete: !!item.is_completed,
  };
};

const displayDataToJSX = (items, navigate) => {
  const displayItems = items
    .sort((lhs, rhs) => {
      return lhs.display_sort_order - rhs.display_sort_order;
    })
    .map((item) => {
      return (
        <Col xs="6" md="4 g-3" key={`${item.key}`}>
          <Alert
            color={item.style}
            className="activatable-20 m-0 p-0 overflow-hidden h-100"
            onClick={() => {
              navigate(item.link_path);
            }}
          >
            {item.complete ? (
              <div className="request-label request-finished">
                Ready for Review
              </div>
            ) : (
              <div className="request-label request-incomplete">
                Pending Input
              </div>
            )}
            <Container className="text-center h-100">
              <Row className="h-100">
                <Col className="d-flex flex-column justify-content-between">
                  <Row xs="m-0 p-0 h-100">
                    <Col className="thumbnail-container d-flex align-items-center justify-content-center">
                      <img
                        src={item.thumbnail_url}
                        className="request-thumbnail img-fluid m-0 pt-3"
                        alt={item.name}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="d-flex request-card-lede align-items-center">
                      <p>
                        <Link to={item.link_path}>{item.name}</Link>
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Alert>
        </Col>
      );
    });
  return displayItems;
};

const MyRequests = (props) => {
  useAuthenticatedAssertion();
  useBasicInfoCollectionRedirect();
  useTitle("Qpid - My Requests");
  useImpression("Home Page / My Requests Page");
  const user = useStytchUser();
  const api = useAPIClient();
  const navigate = useNavigate();
  const { data, isLoading } = api.useCachedUserRequests();
  const dataDisplayItems = data?.requests?.map(itemToDataDisplayItem);
  const displayItems = !!dataDisplayItems
    ? displayDataToJSX(dataDisplayItems, navigate)
    : null;

  return (
    <Container>
      <Row>
        <Col>
          <h2>Hi, {user?.user?.name?.first_name ?? ""}</h2>
        </Col>
      </Row>
      <Row>
        <Col md="9" xs="12">
          <p className="lead">
            Welcome to your dashboard. As your personal shopping concierge, Qpid
            will learn your preferences and priorities and match you with the
            best products for you.
          </p>
        </Col>
      </Row>
      {!!displayItems && (
        <>
          <Row className="justify-content-center">{displayItems}</Row>
          <Row>
            <Col xs="auto" className="mx-auto pt-5">
              <Spinner
                color="primary"
                size="sm"
                type="grow"
                className={isLoading ? "opacity-100" : "opacity-0"}
              />
            </Col>
          </Row>
        </>
      )}
      {!displayItems && (
        <Row>
          <Col xs="auto" className="mx-auto pt-5">
            <Spinner color="primary" />
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default MyRequests;
