import { useLogout } from "../Utilities/Logout";
import { useAuthenticatedAssertion } from "../Utilities/RedirectAssertions";
import { Button, Container, Col, Row, Alert } from "reactstrap";
import LinkButton from "../Components/LinkButton";
import { useTitle } from "../Utilities/Title";
import { useImpression } from "../Utilities/Analytics";

const LogoutComponent = (props) => {
  useAuthenticatedAssertion("/login");
  useTitle("Qpid - Log out");
  useImpression("Logout Page");
  let doLogout = useLogout();
  return (
    <Container>
      <Row>
        <Col xs="auto g-1 mx-auto">
          <Alert color="light">
            <Row>
              <Col>
                <h3>Log out?</h3>
                <p>To use your Qpid profile you'll need to be logged in.</p>
              </Col>
            </Row>
            <Row className="justify-content-start align-items-center">
              <Col xs="auto g-2">
                <Button onClick={doLogout} color="danger">
                  Log out
                </Button>
              </Col>
              <Col xs="auto g-2">
                <LinkButton to="/" color="secondary">
                  Not now
                </LinkButton>
              </Col>
            </Row>
          </Alert>
        </Col>
      </Row>
    </Container>
  );
};

export default LogoutComponent;
