import { Link } from "react-router-dom";
import { Button } from "reactstrap";

function LinkButton(props) {
  return (
    <Link to={props.to} onClick={(e) => e.stopPropagation()}>
      <Button color={props.color} tag="div" className={props.className}>
        {props.children}
      </Button>
    </Link>
  );
}

export default LinkButton;
