import { Container, Col, Row, Card, Button } from "reactstrap";
import Icons from "../Components/Icons";
import { useTitle } from "../Utilities/Title";
import { useImpression } from "../Utilities/Analytics";
const Membership = () => {
  useTitle("Qpid - Membership");
  useImpression("Membership Page");

  return (
    <Container>
      <Row>
        <Col xs="auto mx-auto">
          <h2>Membership Options</h2>
        </Col>
      </Row>
      <Row>
        <Col xs="9 mx-auto mb-3">
          <p>
            Qpid matches you with the best products based on your needs and
            preferences. It can also help you pick the right items for others.
            ;)
          </p>
        </Col>
      </Row>
      <Row className="justify-content-around row-gap-3">
        <Col xs="12" sm="6" md="4">
          <Card className="p-3 h-100">
            <Row>
              <h5>Trial</h5>
              <p>
                Make your first request to try the power of matches by Qpid.
              </p>
              <p className="fs-7">
                <span className="fs-1 fc-darkhighlight">$0</span> for your
                request
              </p>
            </Row>
            <Row className="m-1 mt-auto">
              <Button color="primary" disabled="true">
                Current Plan
              </Button>
            </Row>
            <Row className="m-1">
              <Button outline="true" color="primary">
                Learn More
              </Button>
            </Row>
          </Card>
        </Col>
        <Col xs="12" sm="6" md="4">
          <Card className="p-3 h-100">
            <Row>
              <h5>Qpid Montly</h5>
              <p>Make a well-informed decision on multiple products.</p>
              <p className="fs-7">
                <span className="fs-1 fc-darkhighlight">$9</span> per month
              </p>
            </Row>
            <Row className="m-1 mt-auto">
              <Button color="primary">Select Plan</Button>
            </Row>
            <Row className="m-1">
              <Button outline="true" color="primary">
                Learn More
              </Button>
            </Row>
          </Card>
        </Col>
        <Col xs="12" sm="6" md="4">
          <Card className="p-3 h-100">
            <Row>
              <h5>Qpid Plus</h5>
              <p>Have the power of Qpid available whenever you want it.</p>
              <p className="fs-7">
                <span className="fs-1 fc-darkhighlight">$95</span> per year
              </p>
            </Row>
            <Row className="m-1 mt-auto">
              <Button color="primary">Select Plan</Button>
            </Row>
            <Row className="m-1">
              <Button outline="true" color="primary">
                Learn More
              </Button>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs="auto mx-auto mt-5">
          <h2 className="my-2">Compare Plans</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <table className="product-table table table-hover text-center align-middle">
            <thead>
              <tr>
                <th className="text-start">Usage</th>
                <th>Trial</th>
                <th>Monthly</th>
                <th>Plus</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-start">First Request</td>
                <td>
                  <Icons.Check />
                </td>
                <td>
                  <Icons.Check />
                </td>
                <td>
                  <Icons.Check />
                </td>
              </tr>
              <tr>
                <td className="text-start">Unlimited Requests</td>
                <td>
                  <Icons.XMark />
                </td>
                <td>
                  <Icons.Check />
                </td>
                <td>
                  <Icons.Check />
                </td>
              </tr>
            </tbody>
            <tr>
              <td>&nbsp;</td>
            </tr>
            <thead>
              <tr>
                <th className="text-start">Features</th>
                <th>Trial</th>
                <th>Monthly</th>
                <th>Plus</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-start">
                  Requests in current product categories
                </td>
                <td>
                  <Icons.Check />
                </td>
                <td>
                  <Icons.Check />
                </td>
                <td>
                  <Icons.Check />
                </td>
              </tr>
              <tr>
                <td className="text-start">
                  Early access to 'Coming Soon' products
                </td>
                <td>
                  <Icons.XMark />
                </td>
                <td>
                  <Icons.Check />
                </td>
                <td>
                  <Icons.Check />
                </td>
              </tr>
            </tbody>
            <tr>
              <td>&nbsp;</td>
            </tr>
            <thead>
              <tr>
                <th className="text-start">Support</th>
                <th>Trial</th>
                <th>Monthly</th>
                <th>Plus</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-start">Email us any time</td>
                <td>
                  <Icons.Check />
                </td>
                <td>
                  <Icons.Check />
                </td>
                <td>
                  <Icons.Check />
                </td>
              </tr>
            </tbody>
          </table>
        </Col>
      </Row>
    </Container>
  );
};

export default Membership;
