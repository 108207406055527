import React from "react";
import { StytchLogin } from "@stytch/react";
import { Products } from "@stytch/vanilla-js";
import Constants from "../Constants";
import { Col, Row, Container } from "reactstrap";

const LoginPage = ({ props }) => {
  const styles = {
    container: {
      backgroundColor: "#FFFFFF",
      borderColor: "#ADBCC5",
      borderRadius: "1em",
      width: "100%",
    },
    colors: {
      primary: "#19303D",
      secondary: "#5C727D",
      success: "#0C5A56",
      error: "#8B1214",
    },
    buttons: {
      primary: {
        backgroundColor: "#7E60BC",
        textColor: "#FFFFFF",
        borderColor: "#7E60BC",
        borderRadius: "1.3em",
      },
      secondary: {
        backgroundColor: "#FFFFFF",
        textColor: "#19303D",
        borderColor: "#19303D",
        borderRadius: "1.3em",
      },
    },
    fontFamily: "Arial",
    hideHeaderText: false,
    logo: {
      logoImageUrl: "",
    },
  };

  const config = {
    products: [Products.emailMagicLinks, Products.oauth],
    emailMagicLinksOptions: {
      loginRedirectURL: `${Constants.APP_DOMAIN}/authenticate`,
      loginExpirationMinutes: 60,
      signupRedirectURL: `${Constants.APP_DOMAIN}/authenticate`,
      signupExpirationMinutes: 60,
    },
    oauthOptions: {
      providers: [{ type: "google" }],
      loginRedirectURL: `${Constants.APP_DOMAIN}/authenticate`,
      loginExpirationMinutes: 60,
      signupRedirectURL: `${Constants.APP_DOMAIN}/authenticate`,
      signupExpirationMinutes: 60,
    },
  };

  return (
    <Container>
      <Row>
        <Col className="mx-auto m-0 p-0">
          <StytchLogin config={config} styles={styles} />
        </Col>
      </Row>
    </Container>
  );
};

export default LoginPage;
