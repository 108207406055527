import { useLayoutEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useLocation } from "react-router";
import ErrorUIComponent from "../Components/ErrorUI";

const ErrorPage = (props) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorUI}>{props.children}</ErrorBoundary>
  );
};

function ErrorUI({ error, resetErrorBoundary }) {
  const location = useLocation();
  const [locationState, setLocationState] = useState(null);

  useLayoutEffect(() => {
    if (!!locationState && location !== locationState) {
      resetErrorBoundary();
    }
    setLocationState(location);
    // eslint-disable-next-line
  }, [location, locationState]);

  return (
    <ErrorUIComponent
      message="There's an error that prevents us showing this page."
      details={error?.toString()}
      isTemporary={!!error?.isTemporary}
    />
  );
}

export default ErrorPage;
