import {
  Container,
  Card,
  CardHeader,
  CardBody,
  Col,
  Row,
  Button,
  Alert,
} from "reactstrap";
import Constants from "../Constants";
import Icons from "./Icons";
import { useNavigate } from "react-router-dom";

const ErrorUI = ({ details, isTemporary }) => {
  const navigate = useNavigate();
  return (
    <Container>
      <Row>
        <Col xs="10" className="mx-auto">
          <Row>
            <Col>
              <Alert color="danger">
                <Row className="p-2">
                  <Col xs="12 g-1">
                    <div>
                      <h2>Error</h2>
                    </div>
                  </Col>
                  <Col xs="12 g-1">
                    <p>Sorry about this!</p>
                    <p>
                      Qpid encountered an error when trying to execute your
                      request.
                    </p>
                  </Col>
                  {!!isTemporary && (
                    <Col xs="auto g-1">
                      <Button
                        onClick={() => {
                          navigate(0);
                        }}
                      >
                        <Icons.Refresh /> Reload
                      </Button>
                    </Col>
                  )}
                  {!isTemporary && (
                    <Col xs="auto g-1">
                      <Button
                        onClick={() => {
                          navigate(-1);
                        }}
                      >
                        <Icons.Back /> Go Back
                      </Button>
                    </Col>
                  )}
                  <Col xs="auto g-1">
                    <Button color="danger" href={Constants.APP_DOMAIN}>
                      <Icons.Home /> Return Home
                    </Button>
                  </Col>
                </Row>
              </Alert>
            </Col>
          </Row>
          {!!details && (
            <Row>
              <Col>
                <Card color="light" className="translucent">
                  <CardHeader className="font-monospace smaller-text">
                    Details
                  </CardHeader>
                  <CardBody>
                    <code className="smaller-text">{details}</code>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default ErrorUI;
