import React, { useCallback, useState, useEffect } from "react";
import { useStytch, useStytchUser } from "@stytch/react";
import { useNavigate } from "react-router-dom";
import { useAuthenticatedAssertion } from "../Utilities/RedirectAssertions";
import { useImpression, useTap } from "../Utilities/Analytics";
import Debug from "../Components/Debug";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Alert,
  Row,
  Col,
  Container,
  Spinner,
} from "reactstrap";
import { useTitle } from "../Utilities/Title";

export const UserInfo = ({ continueToPath = null, children }) => {
  useAuthenticatedAssertion();
  const stytchClient = useStytch();
  const user = useStytchUser();
  const navigate = useNavigate();
  useTitle("Qpid - User Info");
  useImpression("User Info / Name Collection Page");
  const didTap = useTap();
  const [firstName, setFirstName] = useState(user.user?.name?.first_name ?? "");
  const [lastName, setLastName] = useState(user.user?.name?.last_name ?? "");
  const [submit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const firstIsValid = useCallback(() => {
    return !!firstName && firstName !== "";
  }, [firstName]);
  const lastIsValid = useCallback(() => {
    return !!lastName && lastName !== "";
  }, [lastName]);

  const handleSubmit = useCallback(
    (e) => {
      didTap("user info update");
      e.preventDefault();
      if (firstIsValid() && lastIsValid()) {
        setLoading(true);
        setSubmit(false);
        return stytchClient.user
          .update({
            name: {
              first_name: firstName,
              last_name: lastName,
            },
          })
          .then(() => setSubmit(true))
          .then(() => !!continueToPath && navigate(continueToPath))
          .finally(() => setLoading(false));
      }
    },
    [
      stytchClient,
      firstName,
      lastName,
      firstIsValid,
      lastIsValid,
      continueToPath,
      navigate,
      didTap,
    ]
  );

  useEffect(() => {
    setFirstName(user.user.name?.first_name ?? "");
    setLastName(user.user.name?.last_name ?? "");
  }, [
    user.user?.name?.first_name,
    user.user?.name?.last_name,
    setFirstName,
    setLastName,
  ]);

  return (
    <>
      <Container>
        <Row>
          <Col xs="12" sm="10" md="8" className="mx-auto">
            <Row>
              <Col>{children}</Col>
            </Row>
            <Row>
              <Col>
                <Alert color="light">
                  <Form>
                    <Row>
                      <FormGroup>
                        <Label for="floatingFNInput">First Name</Label>
                        <Input
                          type="text"
                          onChange={(event) => {
                            setFirstName(event.target.value);
                            console.log(event.target.value);
                          }}
                          value={firstName}
                          invalid={!firstIsValid()}
                          id="floatingFNInput"
                          placeholder="First Name"
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="floatingLNInput">Last Name</Label>
                        <Input
                          type="text"
                          onChange={(event) => setLastName(event.target.value)}
                          value={lastName}
                          invalid={!lastIsValid()}
                          id="floatingLNInput"
                          placeholder="Last Name"
                        />
                      </FormGroup>
                    </Row>
                    <Row xs="justify-content-start align-items-center m-0 p-0">
                      <Col xs="auto p-1">
                        <Button onClick={handleSubmit}>Update</Button>
                      </Col>
                      {submit && (
                        <Col xs="auto">
                          <Alert className="m-0 p-2">Name updated.</Alert>
                        </Col>
                      )}
                      {loading && (
                        <Col xs="auto">
                          <Spinner className="p-0 m-0" />
                        </Col>
                      )}
                    </Row>
                  </Form>
                </Alert>
              </Col>
            </Row>
          </Col>
        </Row>
        <Debug>
          <Row>
            <Col>
              <dl>
                <dd>userid</dd>
                <dl>
                  <code>{user.user.user_id}</code>
                </dl>
                <dd>payload</dd>
                <dl>
                  <code>{JSON.stringify(user.user)}</code>
                </dl>
              </dl>
            </Col>
          </Row>
        </Debug>
      </Container>
    </>
  );
};

export default UserInfo;
