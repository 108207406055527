import { useSearchParams } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

const Debug = (props) => {
  const [searchParams] = useSearchParams();

  return (
    <>
      {!!searchParams.get("debug") && (
        <Card>
          <CardBody>{props.children}</CardBody>
        </Card>
      )}
    </>
  );
};
export default Debug;
