import React from "react";
import { createRoot } from "react-dom/client";
import { StytchProvider } from "@stytch/react";
import { StytchUIClient } from "@stytch/vanilla-js";
import * as amplitude from "@amplitude/analytics-browser";
import App from "./App";
import Constants from "./Constants";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Style.scss";

const stytch = new StytchUIClient(Constants.STYTCH_TOKEN);
amplitude.init("84b1231b6a85b94683544ae06ed335fe");
const root = createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <StytchProvider stytch={stytch}>
      <App />
    </StytchProvider>
  </React.StrictMode>
);
