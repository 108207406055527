import { useState, useEffect, useCallback } from "react";
import { useStytch } from "@stytch/react";
import { usePolling } from "./Polling";
import { useAnalyicsUserID } from "./Analytics";

function useAuthMaintenance() {
  const [lastPoll, setLastPoll] = useState(0);
  const client = useStytch();
  const callback = useCallback(() => {
    setLastPoll((p) => p + 1);
  }, []);
  const removePolling = usePolling(600000, callback);

  const setAnalyticsUserID = useAnalyicsUserID();

  useEffect(() => {
    setAnalyticsUserID(client.user.user_id);
  }, [client.user.user_id, setAnalyticsUserID]);

  useEffect(() => {
    try {
      if (client.session.getSync()) {
        client.session.authenticate({
          session_duration_minutes: 60,
        });
      }
    } catch (error) {
      console.warn("authentication refresh exception", error);
    }
  }, [lastPoll, client]);

  return () => {
    removePolling();
  };
}

export { useAuthMaintenance };
