import { Container, Col, Row, Alert } from "reactstrap";
import Login from "../Components/Login";
import { useLoggedOutAssertion } from "../Utilities/RedirectAssertions";
import { useTitle } from "../Utilities/Title";
import { useImpression } from "../Utilities/Analytics";

function Signup() {
  useLoggedOutAssertion();
  useTitle("Qpid - Sign up");
  useImpression("Signup Page");
  return (
    <Container>
      <Row>
        <Col className="mx-auto">
          <Alert color="primary">
            <h2>Join Qpid</h2>
            <p>
              Sign up or log in to let Qpid help you find your perfect products.
            </p>
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <Login />
        </Col>
      </Row>
    </Container>
  );
}

export default Signup;
